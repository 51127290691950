<script setup lang="ts">
import { useExpirationTimeShortList } from '../infrastructure/store';
import ExpirationTimeList from './List.vue';

const items = useExpirationTimeShortList();
</script>

<template>
	<ExpirationTimeList :items="items" />
</template>
