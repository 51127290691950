<script setup lang="ts">
import { useExpirationTimeLongList } from '../infrastructure/store';
import ExpirationTimeList from './List.vue';

const items = useExpirationTimeLongList();
</script>

<template>
	<ExpirationTimeList :items="items" />
</template>
