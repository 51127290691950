<script setup lang="ts">
import { BaseButton, BaseIcon } from '@legacy-studio/library';
import { useSetExpirationTime } from '../core/application';
import { useExpirationTime } from '../infrastructure/store';
import type { ExpirationTimeList, ExpirationTime } from '../types';

const currentExpirationTime = useExpirationTime();
const setExpirationTime = useSetExpirationTime();

defineProps<{
	items: ExpirationTimeList;
}>();

const handleSetExpirationTime = (item: ExpirationTime) => {
	if (currentExpirationTime?.stringified !== item.stringified) {
		setExpirationTime(item);
	}
};
</script>

<template>
	<div class="flex flex-col gap-4 pb-4">
		<BaseButton
			v-for="item in items.times"
			:key="item.stringified"
			:disabled="currentExpirationTime?.stringified === item.stringified"
			color="alternative-dark"
			outline
			class="w-full"
			:class="{
				'bg-gray-700 text-white': currentExpirationTime?.stringified === item.stringified,
			}"
			@click="handleSetExpirationTime(item)"
		>
			<div class="flex items-center gap-2">
				<BaseIcon name="base--fill--clock" />
				<span>{{ item.stringified }}</span>
			</div>
		</BaseButton>

		<div
			v-for="item in items.extended"
			:key="item.stringified"
			class="space-y-1"
		>
			<div class="text-sm text-gray-500 font-semibold">
				{{ item.label }}
			</div>
			<BaseButton
				:disabled="currentExpirationTime?.stringified === item.stringified"
				color="alternative-dark"
				outline
				class="w-full !p-0"
				:class="{
					'bg-gray-700 text-white': currentExpirationTime?.stringified === item.stringified,
				}"
				@click="handleSetExpirationTime(item)"
			>
				<div class="flex items-center gap-2 text-sm">
					<span>{{ item.stringified }}</span>
				</div>
			</BaseButton>
		</div>
	</div>
</template>
