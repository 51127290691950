import { saveExpirationTime } from '../../infrastructure/store';
import type { ExpirationTime } from '../../types';
import { useSidebarStore } from '~/src/widgets/terminal/store';

export const useSetExpirationTime = () => {
	return (expirationTime: ExpirationTime) => {
		const drawerStore = useSidebarStore();
		saveExpirationTime(expirationTime);
		drawerStore.closeDrawer();
	};
};
